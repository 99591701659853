import { createSlice } from "@reduxjs/toolkit";
import {
  create,
  deleteProduct,
  getFilterProductsFromCategoryAndType,
  loadProduct,
  updateProduct,
} from "../../http/productApi";

const productSlice = createSlice({
  name: "product",
  initialState: {
    list: [],
    loading: false,
    error: null,
  },
  reducers: {
    clearList(state) {
      state.list = [];
    },
    searchList(state, action) {
      state.list = [action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loadProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload;
      })
      .addCase(loadProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(create.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(create.fulfilled, (state, action) => {
        state.loading = false;
        state.list.push(action.payload);
      })
      .addCase(create.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getFilterProductsFromCategoryAndType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getFilterProductsFromCategoryAndType.fulfilled,
        (state, action) => {
          state.loading = false;
          state.list = action.payload;
        }
      )
      .addCase(
        getFilterProductsFromCategoryAndType.rejected,
        (state, action) => {
          state.loading = false;
          console.log("");

          state.error = action.payload;
        }
      )
      // Удаление продукта
      .addCase(deleteProduct.pending, (state) => {
        state.loading = true; // Используем loading вместо isLoading
        state.error = null; // Обнуляем ошибку перед началом запроса
      })
      .addCase(deleteProduct.fulfilled, (state, { payload }) => {
        const { id, amount } = payload;

        if (amount === 0) {
          // Удалить товар из списка, если amount стал 0
          state.list = state.list.filter((item) => item.id !== id);
        } else {
          // Найти и обновить amount товара
          const product = state.list.find((item) => item.id === id);
          if (product) {
            product.amount = amount; // Обновляем поле amount
          }
        }

        state.loading = false; // После выполнения запроса меняем loading на false
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.loading = false; // В случае ошибки также меняем loading на false
        state.error = action.payload || "Ошибка при удалении товара"; // Обрабатываем ошибку
      })

      // builder.addCase(deleteProduct.pending, (state) => {
      //   state.isLoading = true;
      // });
      // builder.addCase(deleteProduct.fulfilled, (state, { payload }) => {
      //   state.list = state.list.filter((item) => item.id !== payload.id);
      //   state.isLoading = false;
      // });
      // builder
      //   .addCase(deleteProduct.rejected, (state) => {
      //     state.isLoading = false;
      //   })
      .addCase(updateProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.list.findIndex(
          (item) => item.id === action.payload.id
        );
        if (index !== -1) {
          state.list[index] = action.payload;
        }
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { searchList } = productSlice.actions;
export const { clearList } = productSlice.actions;
export const productReducer = productSlice.reducer;
