import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import stl from "./editProductId.module.scss";
import MyButton from "../button/MyButton";
// import { loadBrands } from "../../http/brandApi";
// import { loadModels } from "../../http/modelApi";
import { loadTypes } from "../../http/typeApi";

const EditProductId = ({ productData, onSave, onCancel }) => {
  const dispatch = useDispatch();

  // const allBrands = useSelector((state) => state.brandList.list);
  // const allModels = useSelector((state) => state.modelList.list);
  const allTypes = useSelector((state) => state.typeList.list);

  useEffect(() => {
    // dispatch(loadBrands());
    // dispatch(loadModels());
    dispatch(loadTypes());
  }, [dispatch]);

  const {
    control,
    register,
    handleSubmit,
    setError,
    setValue,
    watch,
    formState: { errors, dirtyFields, isSubmitting },
  } = useForm({
    defaultValues: {
      name: productData.name,
      description: productData.description,
      price: productData.price,
      amount: productData.amount,
      condition: productData.condition,
      qrCode: productData.qrCode,
      internalCode: productData.internalCode,
      manufacturerPartNumber: productData.manufacturerPartNumber,
      weight: productData.weight,
      length: productData.length,
      width: productData.width,
      height: productData.height,
      userId: productData.userId,
      typeId: productData.typeId,
      brandIds: productData?.brands?.map((e) => e.id),
      modelIds: productData?.models?.map((e) => e.id),
    },
  });

  const generateOptions = (types, parentID = null, level = 0) => {
    return types
      .filter((type) => type.parentID === parentID)
      .flatMap((type) => [
        <option key={type.id} value={type.id}>
          {`${"—".repeat(level)} ${type.name}`}
        </option>,
        ...generateOptions(types, type.id, level + 1),
      ]);
  };

  const onSubmit = async (data) => {
    const updatedFields = Object.keys(dirtyFields).reduce((acc, key) => {
      acc[key] = data[key]; // Записываем только изменённые значения

      return acc;
    }, {});

    if (Object.keys(updatedFields).length === 0) {
      return;
    }

    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      onSave(
        updatedFields
        //  productId: productData.id
      );
    } catch (error) {
      setError("root", { message: "Произошла ошибка при обновлении детали" });
    }
  };

  const isTypesLoaded = allTypes && allTypes.length > 0;

  return (
    <div className={stl.part}>
      <form className={stl.form} onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="name">Название детали</label>
        <input
          {...register("name", {
            required: "Имя обязательно",
            minLength: {
              value: 3,
              message: "Имя должно быть не менее 3 символов",
            },
          })}
          type="text"
          placeholder="Название детали"
        />
        {errors.name && (
          <div className="text-red-500">{errors.name.message}</div>
        )}
        <label htmlFor="name">Описание детали</label>
        <input
          {...register("description", {
            required: "Описание обязательно",
            minLength: {
              value: 3,
              message: "Описание должно быть не менее 3 символов",
            },
          })}
          type="text"
          placeholder="Описание детали"
        />
        {errors.description && (
          <div className="text-red-500">{errors.description.message}</div>
        )}
        <label htmlFor="name">Цена детали</label>

        <input
          {...register("price", {
            required: "Цена обязательна",
            valueAsNumber: true,
          })}
          type="number"
          placeholder="Цена детали"
        />
        {errors.price && (
          <div className="text-red-500">{errors.price.message}</div>
        )}
        <label htmlFor="name">Количество деталей</label>
        <input
          {...register("amount", {
            required: "Количество обязательно",
            valueAsNumber: true,
          })}
          type="number"
          placeholder="Количество деталей"
        />
        {errors.amount && (
          <div className="text-red-500">{errors.amount.message}</div>
        )}
        <label htmlFor="name">Состояние детали</label>
        <select
          {...register("condition", { required: "Состояние обязательно" })}
        >
          <option disabled value="">
            Выбрать состояние
          </option>
          <option value="new">Новая</option>
          <option value="used">Б/У</option>
          <option value="refurbished">Восстановленная</option>
        </select>
        {errors.condition && (
          <div className="text-red-500">{errors.condition.message}</div>
        )}
        <label htmlFor="name">Внутренний код</label>
        <input
          {...register("internalCode", {
            required: "Внутренний код обязателен",
          })}
          type="text"
          placeholder="Внутренний код"
        />
        {errors.internalCode && (
          <div className="text-red-500">{errors.internalCode.message}</div>
        )}
        <label htmlFor="name">QR-код</label>

        <input
          {...register("qrCode", {
            required: "QR-код обязателен",
          })}
          type="text"
          placeholder="QR-код"
        />
        {errors.qrCode && (
          <div className="text-red-500">{errors.qrCode.message}</div>
        )}

        <label htmlFor="name">Код производителя</label>
        <input
          {...register("manufacturerPartNumber", {
            required: "Код производителя обязателен",
          })}
          type="text"
          placeholder="Код производителя"
        />
        {errors.manufacturerPartNumber && (
          <div className="text-red-500">
            {errors.manufacturerPartNumber.message}
          </div>
        )}
        <label htmlFor="name">Тип детали</label>
        {isTypesLoaded ? (
          <select {...register("typeId", { required: "Тип обязателен" })}>
            <option disabled value="">
              Выбрать тип
            </option>
            {generateOptions(allTypes)}
          </select>
        ) : (
          <p>Загрузка типов...</p>
        )}
        {errors.typeId && (
          <div className="text-red-500">{errors.typeId.message}</div>
        )}
        <label htmlFor="name">Масса детали</label>
        <div className={stl.inputContainer}>
          <input
            {...register("weight", {
              required: "Масса обязательна",
              validate: (value) => !isNaN(value) && parseFloat(value) >= 0,
            })}
            type="number"
            step="0.01"
            placeholder="Масса"
            className={stl.input} // Добавляем отступ, чтобы текст не перекрывалось
          />
          <span className={stl.inputSuffix}>кг.</span>
        </div>
        {errors.weight && (
          <div className="text-red-500">{errors.weight.message}</div>
        )}

        <label htmlFor="name">Длина детали</label>
        <div className={stl.inputContainer}>
          <input
            {...register("length", {
              required: "Длина обязательна",
              validate: (value) => !isNaN(value) && parseFloat(value) >= 0,
            })}
            type="number"
            step="0.001"
            placeholder="Длина"
            className={stl.input} // Добавляем отступ, чтобы текст не перекрывалось
          />
          <span className={stl.inputSuffix}>см.</span>
        </div>
        {errors.length && (
          <div className="text-red-500">{errors.length.message}</div>
        )}
        <label htmlFor="name">Ширина детали</label>
        <div className={stl.inputContainer}>
          <input
            {...register("width", {
              required: "Ширина обязательна",
              valueAsNumber: true,
            })}
            type="number"
            step="0.01"
            placeholder="Ширина"
            className={stl.input} // Добавляем отступ, чтобы текст не перекрывалось
          />
          <span className={stl.inputSuffix}>см.</span>
        </div>
        {errors.width && (
          <div className="text-red-500">{errors.width.message}</div>
        )}
        <label htmlFor="name">Высота детали</label>
        <div className={stl.inputContainer}>
          <input
            {...register("height", {
              required: "Высота обязательна",
              validate: (value) => !isNaN(value) && parseFloat(value) >= 0,
            })}
            type="number"
            step="0.01"
            placeholder="Высота"
            className={stl.input} // Добавляем отступ, чтобы текст не перекрывалось
          />
          <span className={stl.inputSuffix}>см.</span>
        </div>
        {errors.height && (
          <div className="text-red-500">{errors.height.message}</div>
        )}
        {/* Кнопки действия */}

        <MyButton type="submit" disabled={isSubmitting}>
          Сохранить
        </MyButton>
        <MyButton type="button" onClick={onCancel}>
          Отмена
        </MyButton>

        {errors.root && (
          <div className="text-red-500">{errors.root.message}</div>
        )}
      </form>
    </div>
  );
};

export default EditProductId;
