import { Routes, Route } from "react-router-dom";
import { publicRoutes } from "../routes";
import Header from "./header/Header";
import { useSelector } from "react-redux";
import Admin from "../pages/Admin";
import CreateType from "../UI/type/Type";
import CreatePart from "../UI/createPart/CreatePart";
import EditProduct from "../UI/editProduct/EditProduct";
import Category from "../UI/category/Category";

const AppRouter = () => {
  const { isAuth } = useSelector((state) => state.isAuth);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Header />
      <Routes>
        {isAuth && (
          <Route path="/admin" element={<Admin />}>
            <Route path="category" element={<Category />} />
            <Route path="types" element={<CreateType />} />
            <Route path="product" element={<CreatePart />} />
            <Route path="edit" element={<EditProduct />} />
          </Route>
        )}
        {publicRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
        {/* <Route path="*" element={<Shop />} /> */}
      </Routes>
    </div>
  );
};

export default AppRouter;
