import { $authHost, $host } from "./index";
import { createAsyncThunk } from "@reduxjs/toolkit";

// Загрузка всех картинок одной детали
export const loadImageOneDetailServer = createAsyncThunk(
  "@@image/loadlImageOneDetail",
  async (imageId, { rejectWithValue }) => {
    try {
      const response = await $host.get(`api/image/product/${imageId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Создание нового типа детали
export const addImageProduct = createAsyncThunk(
  "@@image/addImage",
  async ({ productId, formData }, { rejectWithValue }) => {
    try {
      const response = await $authHost.post(
        `api/image/product/${productId}`,
        formData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Создание нового типа детали
export const deleteImageServer = createAsyncThunk(
  "@@image/deleteImage",
  async (imageId, { rejectWithValue }) => {
    try {
      const response = await $authHost.delete(`api/image/product/${imageId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// export const deleteType = createAsyncThunk(
//   "@@type/delete-type",
//   async (id, { rejectWithValue }) => {
//     try {
//       console.log(id, "id!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
//       const response = await $authHost.delete(`api/type/${id}`);
//       console.log(response.data, "response!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// // Обновление типа детали по id
// export const updateType = createAsyncThunk(
//   "@@type/update-type",
//   async (
//     { id, name, description, parentID, order, imageURL },
//     { rejectWithValue }
//   ) => {
//     try {
//       const response = await $authHost.patch(`api/type/${id}`, {
//         name,
//         description,
//         parentID,
//         order,
//         imageURL,
//       });
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// export const typesByModelWithProducts = createAsyncThunk(
//   "@@type/filter-type",
//   async ({ brandId, modelId }, { rejectWithValue }) => {
//     try {
//       const response = await $authHost.get("api/type/filter", {
//         params: {
//           brandId,
//           modelId,
//         },
//       });
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );
