import React, { useRef, useState } from "react";
import stl from "./imageCropper.module.scss";
import "react-image-crop/src/ReactCrop.scss";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import setCanvasPreview from "./setCanvasPreview";
import { useDispatch } from "react-redux";
import { addImageProduct } from "../../http/imageApi";

const ASPECT_RATIO = 4 / 3; // Новое соотношение сторон
const MIN_DIMENSION = { width: 800, height: 600 }; // Новые минимальные размеры

const ImageCropper = ({ setChange, productId }) => {
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [crop, setCrop] = useState();
  const [error, setError] = useState("");
  const [finalImg, setFinalImg] = useState();

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleSaveImg = () => {
    const base64ToFile = (base64, fileName) => {
      const arr = base64.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], fileName, { type: mime });
    };

    const file = base64ToFile(finalImg, "image.jpg");
    // Создаем объект FormData
    const formData = new FormData();
    formData.append("image", file);
    console.log(file);

    dispatch(addImageProduct({ productId, formData }));
  };
  const back = (e) => {
    e.preventDefault();
    if (typeof setChange === "function") {
      setChange("");
    } else {
      console.error("setChange is not a function");
    }
  };

  const onSelectFile = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      imageElement.src = imageUrl;

      imageElement.addEventListener("load", (e) => {
        if (error) setError("");
        const { naturalWidth, naturalHeight } = e.currentTarget;

        if (
          naturalWidth < MIN_DIMENSION.width ||
          naturalHeight < MIN_DIMENSION.height
        ) {
          setError(
            `Изображение должно быть не менее ${MIN_DIMENSION.width} x ${MIN_DIMENSION.height} пикселей.`
          );
          return setImgSrc("");
        }
      });

      setImgSrc(imageUrl);
    });
    reader.readAsDataURL(file);
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION.width / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: "%", // Процентная обрезка
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  return (
    <div>
      <button onClick={toggleVisibility}>
        {isVisible ? "Скрыть панель" : "Загрузить фото"}
      </button>
      <button onClick={back}>назад</button>
      <div className={`${stl.panel} ${isVisible ? stl.visible : stl.hidden}`}>
        {/* <p>Это содержимое видимой панели</p> */}
        <label>
          <span>Добавить фото: </span>
          <input type="file" accept="image/*" onChange={onSelectFile} />
        </label>
        {error && <p>{error}</p>}
        {imgSrc && (
          <div className={stl.imgSrc}>
            <ReactCrop
              crop={crop}
              onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
              keepSelection
              aspect={ASPECT_RATIO}
            >
              <img
                ref={imgRef}
                src={imgSrc}
                alt="upload"
                style={{ maxHeight: "70vh" }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
            <button
              onClick={() => {
                setCanvasPreview(
                  imgRef.current,
                  previewCanvasRef.current,
                  convertToPixelCrop(
                    crop,
                    imgRef.current.width,
                    imgRef.current.height
                  )
                );
                const dataUrl = previewCanvasRef.current.toDataURL();
                setFinalImg(dataUrl);
              }}
            >
              Обрезать картинку
            </button>
            {crop && (
              <canvas
                ref={previewCanvasRef}
                style={{
                  border: "1px solid black",
                  objectFit: "contain",
                  width: 800,
                  height: 600,
                  marginTop: "10px",
                }}
              />
            )}
          </div>
        )}
        <button onClick={handleSaveImg}>Сохранить</button>
        <button onClick={toggleVisibility}>Закрыть</button>
      </div>
    </div>
  );
};

export default ImageCropper;
