import { $authHost, $host } from "./index";
import { jwtDecode } from "jwt-decode";

export const registration = async (dataAuth) => {
  const { data } = await $host.post("api/user/registration", {
    dataAuth,
    role: "MANAGER",
  });
  localStorage.setItem("token", data.token);
  return jwtDecode(data.token);
};
export const login = async ({ email, password }) => {
  const { data } = await $host.post("api/user/login", {
    email,
    password,
  });

  localStorage.setItem("token", data.token);
  return jwtDecode(data.token);
};
// export const check = async (options = {}) => {
//   try {
//     const { data } = await $authHost.get("api/user/auth", options);
//     localStorage.setItem("token", data?.token);
//     return jwtDecode(data?.token);
//   } catch (error) {
//     console.error("Check error:", error);
//     throw error;
//   }
// };

export const check = async () => {
  const { data } = await $authHost.get("api/user/auth");
  localStorage.setItem("token", data.token);
  return jwtDecode(data.token);
};
