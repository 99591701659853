import React, { useState } from "react";
import style from "./productItemShop.module.scss";

const ProductItem = ({ props }) => {
  const [modalImage, setModalImage] = useState(null); // Для модального окна

  const handleImageClick = (image) => {
    setModalImage(image); // Устанавливаем изображение для просмотра
  };

  const closeModal = () => {
    setModalImage(null); // Закрываем модальное окно
  };

  return (
    <div className={style.productItem}>
      <div className={style.productItem__content}>
        <div>
          {/* Прокручивающийся альбом */}
          <div className={style.productItem__imageGallery}>
            {props.imageProducts?.map((image, index) => (
              <img
                key={index}
                className={style.productItem__image}
                src={`${process.env.REACT_APP_API_URL}/images/products/${image.card}`}
                alt={`${props.name}`}
                onClick={() =>
                  handleImageClick(
                    `${process.env.REACT_APP_API_URL}/images/products/${image.desktopFull}`
                  )
                }
              />
            ))}
          </div>
          <strong>
            <h2>{props.name}</h2>
          </strong>
          <div style={{ display: "flex", gap: "0.5rem" }}>
            <p> Номер детали: {props.manufacturerPartNumber}</p>
          </div>

          <div>Состояние: {props.condition}</div>

          <div>Цена: {props.price}р.</div>
        </div>
        {/* <button className={style.productItem__btns}>Купить</button> */}
      </div>

      {/* Модальное окно */}
      {modalImage && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
          onClick={closeModal}
        >
          <img
            src={modalImage}
            alt="Просмотр изображения"
            style={{
              maxWidth: "90%",
              maxHeight: "90%",
              borderRadius: "5px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ProductItem;
