import { createSlice } from "@reduxjs/toolkit";

import {
  createCategory,
  deleteCategory,
  loadCategory,
  updateCategory,
} from "../../http/categoryApi";

const categorySlice = createSlice({
  name: "category",
  initialState: {
    list: [],
    filterCategory: [],
    selectedCategory: null,
    loading: false,
    error: null,
  },
  reducers: {
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle loadModels
      .addCase(loadCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loadCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload;
      })
      .addCase(loadCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle createModel
      .addCase(createCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.list.push(action.payload);
      })
      .addCase(createCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle deleteModel
      .addCase(deleteCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.list = state.list.filter((item) => item.id !== action.payload.id);
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle updateModel
      .addCase(updateCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.list.findIndex(
          (item) => item.id === action.payload.id
        );
        if (index !== -1) {
          state.list[index] = action.payload;
        }
      })
      .addCase(updateCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    // // Handle getBackFilterModels
    // .addCase(getBackFilterModels.pending, (state) => {
    //   state.loading = true;
    //   state.error = null;
    // })
    // .addCase(getBackFilterModels.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.filterModel = action.payload.data; // Данные записываются в filterModel
    // })
    // .addCase(getBackFilterModels.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload;
    // });
  },
});

// Экспорт редьюсера
export const { setSelectedCategory } = categorySlice.actions;
export const categoryReducer = categorySlice.reducer;
