import { createSlice } from "@reduxjs/toolkit";
import {
  addImageProduct,
  deleteImageServer,
  loadImageOneDetailServer,
} from "../../http/imageApi";

const imageSlice = createSlice({
  name: "images",
  initialState: {
    list: [],
    loading: false,
    error: null,
  },
  reducers: {
    clearListImg(state) {
      state.list = [];
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(addImageProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addImageProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.list.push(action.payload.images);
      })
      .addCase(addImageProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.images;
      })
      .addCase(loadImageOneDetailServer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loadImageOneDetailServer.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload.images;
      })
      .addCase(loadImageOneDetailServer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.images;
      });
    builder.addCase(deleteImageServer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteImageServer.fulfilled, (state, { payload }) => {
      console.log("payload images", payload);
      console.log("state.list images", state.list);

      state.list = state.list.filter((item) => item.id !== payload.id);
      state.loading = false;
    });
    builder.addCase(deleteImageServer.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { clearListImg } = imageSlice.actions;
export const imageReducer = imageSlice.reducer;
