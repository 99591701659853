import Search from "../../UI/search/Search";
import style from "./header.module.scss";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { searchProducts } from "../../http/productApi";
// import { searchList } from "../../features/slice/productSlice";
import { setIsAuth } from "../../features/slice/isAuth";
import { useNavigate } from "react-router-dom";
import { LOGIN_ROUTE } from "../../utils/consts";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuth } = useSelector((state) => state.isAuth);
  // console.log(isAuth);

  const click = (event) => {
    console.log(event);

    event.preventDefault();
    dispatch(setIsAuth(false));
    navigate(LOGIN_ROUTE);
  };

  // const handleChange = (e) => {
  //   console.log("search header", e.target.value);
  //   dispatch(searchProducts(e.target.value));
  // };

  return (
    <div className={style.wrapper}>
      <h1 className={style.ps}>Zavediauto.ru </h1>

      <header className={style.header}>
        <div style={{ margin: " 15px 0" }}>
          <a
            style={{ textDecoration: "none", color: "#e88401" }}
            href="tel:+79033281123"
          >
            Позвонить +79033281123
          </a>
        </div>
        <div className={style.button}>
          <NavLink className={style.nav} to="/">
            Главная
          </NavLink>
          {/* 
          <NavLink className={style.nav} to="/about">
            О компании
          </NavLink> */}
          {isAuth && (
            <>
              <NavLink className={style.nav} to="/admin">
                Работа с товарами
              </NavLink>
              <NavLink className={style.nav} onClick={click}>
                Выйти
              </NavLink>
            </>
          )}
          {/*  : (
            <NavLink className={style.nav} to={LOGIN_ROUTE}>
              Войдите
            </NavLink>
          )}*/}
        </div>
      </header>
      <Search />
    </div>
  );
};

export default Header;
