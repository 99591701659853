import React from "react";
import stl from "./admin.module.scss";
import MyButton from "../UI/button/MyButton";
import { Link, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const Admin = () => {
  const role = useSelector((state) => state.user.user.role);

  // const [modalActive, setModalActive] = useState(false);
  // const [modalType, setModalType] = useState(null);

  // const modalBrands = () => {
  //   setModalType("brands");
  //   setModalActive(true);
  // };
  // const modalMoels = () => {
  //   setModalType("models");
  //   setModalActive(true);
  // };
  // const modalTypes = () => {
  //   setModalType("types");
  //   setModalActive(true);
  // };
  // const modalPart = () => {
  //   setModalType("part");
  //   setModalActive(true);
  // };
  return (
    <div className={stl.wrapper}>
      {/* <Modal visible={modalActive} setVisible={setModalActive}>
        {modalType === "brands" ? (
          <Brands setVisible={setModalActive} />
        ) : modalType === "models" ? (
          <Model setVisible={setModalActive} />
        ) : modalType === "types" ? (
          <PartType setVisible={setModalActive} />
        ) : modalType === "part" ? (
          <Part setVisible={setModalActive} />
        ) : null}
      </Modal> */}
      <div>
        <Link to="/admin/category">
          <MyButton>Добавить модель</MyButton>
        </Link>
        <Link to="/admin/types">
          <MyButton>Добавить тип</MyButton>
        </Link>{" "}
        <Link to="/admin/product">
          {" "}
          <MyButton>Добавить товар</MyButton>
        </Link>
        <Link to="/admin/edit">
          {" "}
          <MyButton>Редактировать товар</MyButton>
        </Link>
        {role === "ADMIN" && (
          <Link to="/admin/user">
            {" "}
            <MyButton>Пользователь</MyButton>
          </Link>
        )}
        <Outlet />
      </div>
    </div>
  );
};

export default Admin;
