import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ADMIN_ROUTE, LOGIN_ROUTE } from "../utils/consts";
import { login, registration } from "../http/userApi";
import MyButton from "../UI/button/MyButton";
import { useDispatch } from "react-redux";
import { setUser } from "../features/slice/userSlice";
import { setIsAuth } from "../features/slice/isAuth";

const Auth = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLogin = location.pathname === LOGIN_ROUTE;

  const [dataAuth, setDataAuth] = useState({
    firstName: "",
    lastName: "",
    brandName: "",
    email: "",
    password: "",
    address: "",
    phone: "",
  });

  const click = async (e) => {
    e.preventDefault();
    try {
      let data;
      if (isLogin) {
        data = await login(dataAuth);
      } else {
        data = await registration(dataAuth);
      }
      dispatch(setUser(data));
      dispatch(setIsAuth(true));
      navigate(ADMIN_ROUTE);
    } catch (e) {
      alert(e.response.data.message);
    }
  };
  return (
    <div
      // className="d-flex justify-content-center align-items-center "
      style={{ height: window.innerHeight - 54 }}
    >
      <div style={{ width: "600px" }}>
        <h2 className="m-auto">{isLogin ? "Авторизация" : "Регистрация"}</h2>
        <form>
          <input
            className="mt-3"
            placeholder="Введите ваш email..."
            value={dataAuth.email}
            onChange={(e) =>
              setDataAuth({ ...dataAuth, email: e.target.value })
            }
          />
          <input
            className="mt-3"
            placeholder="Введите пароль..."
            value={dataAuth.password}
            onChange={(e) =>
              setDataAuth({ ...dataAuth, password: e.target.value })
            }
            type="password"
          />
          {isLogin ? (
            <div>
              {/* Нет аккаунта?{" "}
              <NavLink to={REGISTRATION_ROUTE}>Зарегистрируйся!</NavLink> */}
            </div>
          ) : (
            <div>
              <input
                className="mt-3"
                placeholder="Введите ваше имя..."
                value={dataAuth.firsName}
                onChange={(e) =>
                  setDataAuth({ ...dataAuth, firstName: e.target.value })
                }
              />
              <input
                className="mt-3"
                placeholder="Введите вашу фамилию..."
                value={dataAuth.lastName}
                onChange={(e) =>
                  setDataAuth({ ...dataAuth, lastName: e.target.value })
                }
              />
              <input
                className="mt-3"
                placeholder="Введите название бренда..."
                value={dataAuth.brandName}
                onChange={(e) =>
                  setDataAuth({ ...dataAuth, brandName: e.target.value })
                }
              />
              <input
                className="mt-3"
                placeholder="Введите ваш адрес..."
                value={dataAuth.address}
                onChange={(e) =>
                  setDataAuth({ ...dataAuth, address: e.target.value })
                }
              />
              <input
                className="mt-3"
                placeholder="Введите ваш телефон..."
                value={dataAuth.phone}
                onChange={(e) =>
                  setDataAuth({ ...dataAuth, phone: e.target.value })
                }
              />
              Есть аккаунт? <NavLink to={LOGIN_ROUTE}>Войдите!</NavLink>
            </div>
          )}

          <MyButton
            variant={"outline-success"}
            className="ml-auto"
            onClick={click}
          >
            {isLogin ? "Войти" : "Регистрация"}
          </MyButton>
        </form>
      </div>
    </div>
  );
};

export default Auth;
