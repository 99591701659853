import { createAsyncThunk } from "@reduxjs/toolkit";
import { $authHost, $host } from "./index";

export const create = createAsyncThunk(
  "@@products/create-product",
  async (product, { rejectWithValue }) => {
    try {
      const response = await $authHost.post("api/product", product);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const loadProduct = createAsyncThunk(
  "@@products/getAll-product",
  async (_, { rejectWithValue }) => {
    try {
      const response = await $host.get("api/product");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const searchProducts = createAsyncThunk(
  "@@products/gsearchProducts",
  async (query, { rejectWithValue }) => {
    // Добавляем параметр `query`
    try {
      const response = await $host.get("api/product/search", {
        params: { query }, // Передаем `query` как параметр запроса
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong"); // Учитываем возможное отсутствие `response`
    }
  }
);

export const getFilterProductsFromCategoryAndType = createAsyncThunk(
  "@@products/getFilterProductsFromCategoryAndType-product",
  async ({ category, typeId, userId }, { rejectWithValue }) => {
    try {
      const response = await $host.get(
        "api/product/getFilterProductsFromCategoryAndType/",
        { params: { category, typeId, userId } }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "@@products/delete-product",
  async (id, { rejectWithValue }) => {
    try {
      const response = await $authHost.delete(`api/product/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateProduct = createAsyncThunk(
  "@@products/update-product",
  async ({ id, updatedData }, { rejectWithValue }) => {
    try {
      const response = await $authHost.patch(`api/product/${id}`, {
        updatedData,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
