import MyButton from "../button/MyButton";
import EditProductId from "../editProductId/EditProductId";
import ImageCropper from "../imageCropper/ImageCropper";
import GalleryImagesServer from "../galleryImagesServer/GalleryImagesServer";
import { useDispatch } from "react-redux";
import { clearListImg } from "../../features/slice/imageSlice";
import style from "./productItem.module.scss";
import { useState } from "react";
import Modal from "../../components/modal/Modal";
import YON from "../../components/yon/Yon";

const ProductItem = ({
  product,
  onDelete,
  onSave,
  activeState,
  setActiveState,
}) => {
  const dispatch = useDispatch();

  const [modalActive, setModalActive] = useState(false); // Управление модальным окном
  const [delId, setDelId] = useState(null); // ID для подтверждения удаления

  const handleEdit = () => {
    setActiveState(product.id, "edit"); // Устанавливаем режим редактирования
  };

  const handleAddImg = () => {
    dispatch(clearListImg());
    setActiveState(product.id, "image"); // Устанавливаем режим добавления изображения
  };

  const handleSave = (updatedData) => {
    onSave({ updatedData, id: product.id });
    setActiveState(null, ""); // Сбрасываем состояние после сохранения
  };

  const handleCancel = () => {
    setActiveState(null, ""); // Отменяем текущее действие
  };

  // const handleDelete = () => {
  //   onDelete(product.id);
  // };

  const handleDeleteClick = (id) => {
    setDelId(id);
    setModalActive(true); // Открыть модальное окно
  };

  const deleteClick = (productId) => {
    onDelete(productId);
    setDelId(null);
  };

  const isActive =
    activeState.id === product.id && activeState.state === "image";
  const isEditing =
    activeState.id === product.id && activeState.state === "edit";

  return (
    <div>
      {isEditing && (
        <div>
          <EditProductId
            productData={product}
            onSave={handleSave}
            onCancel={handleCancel}
          />
        </div>
      )}

      {isActive && !isEditing && (
        <>
          <h1>{product.name}</h1>
          <GalleryImagesServer product={product} />
          <ImageCropper
            productId={product?.id}
            setChange={() => setActiveState(null, "")}
          />
        </>
      )}

      {!isEditing && !isActive && (
        <div>
          <div className={style.nameItemAmount}>
            {" "}
            <h1>{product.name}</h1>{" "}
            <p className={style.p}>Количество: {product.amount} шт.</p>
          </div>
          <div>
            {/* Прокручивающийся альбом */}
            <div className={style.productItem__imageGallery}>
              {product.imageProducts?.map((image, index) => (
                <img
                  key={index}
                  className={style.productItem__image}
                  src={`${process.env.REACT_APP_API_URL}/images/products/${image.mini}`}
                  alt={`${product.name}`}
                  // onClick={() =>
                  //   handleImageClick(
                  //     `${process.env.REACT_APP_API_URL}/images/products/${image.desktopFull}`
                  //   )
                  // }
                />
              ))}
            </div>
          </div>
          <MyButton onClick={handleEdit}>Редактировать</MyButton>
          <MyButton onClick={handleAddImg} color="green">
            Добавить картинку
          </MyButton>
          <MyButton onClick={() => handleDeleteClick(product.id)} color="red">
            Удалить
          </MyButton>
          {/* Модальное окно */}
          <Modal visible={modalActive} setVisible={setModalActive}>
            {delId ? (
              <YON
                setVisible={setModalActive}
                del={deleteClick}
                id={delId}
                text="Подтвердите удаление товара."
              />
            ) : (
              <></>
            )}
          </Modal>
        </div>
      )}
    </div>
  );
};

export default ProductItem;
