import React, { useEffect } from "react";
import stl from "./shop.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { loadProduct } from "../http/productApi";
import ProductItemShop from "./productItemShop/ProductItemShop";

const Shop = () => {
  const allProducts = useSelector((state) => state.productList.list);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadProduct());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={stl.mapPage}>
      {allProducts && allProducts.length > 0 ? (
        allProducts.map((item) => (
          <ProductItemShop props={item} key={item.id} />
        ))
      ) : (
        <p>Товары не загружены</p>
      )}
    </div>
  );
};

export default Shop;
