import { createSlice } from "@reduxjs/toolkit";

// Начальное состояние
const initialState = {
  user: {},
};

// Создание слайса
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
  },
});

// Экспортируем действия и редуктор
export const { setUser } = userSlice.actions;
export const userReducer = userSlice.reducer;
