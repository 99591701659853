import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import stl from "./search.module.scss";
import { searchProducts } from "../../http/productApi";
import { searchList } from "../../features/slice/productSlice";

export default function Search() {
  const dispatch = useDispatch();
  const searchProduct = useSelector((state) => state.searchList.list);

  const [isVisible, setIsVisible] = useState(false);

  const handleChange = (event) => {
    dispatch(searchProducts(event.target.value));
    setIsVisible(true); // Показываем список при вводе
  };

  const handleSearchBlur = (e) => {
    setTimeout(() => {
      setIsVisible(false); // Скрываем список после небольшой задержки
    }, 100);
  };

  const handleClick = (product) => {
    dispatch(searchList(product));
    setIsVisible(false); // Скрываем список после выбора элемента
  };

  return (
    <div className={stl.wrapper}>
      <input
        type="text"
        className={stl.buttonSearch}
        onChange={handleChange}
        onBlur={handleSearchBlur}
        placeholder="Поиск..."
      />
      {isVisible && searchProduct && searchProduct.length > 0 && (
        <div
          className={stl.searchList}
          onMouseDown={(e) => e.preventDefault()} // Блокируем потерю фокуса при клике на список
        >
          {searchProduct.map((item) => (
            <div
              key={item.id}
              onClick={() => handleClick(item)}
              className={stl.searchItem}
            >
              {item.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
