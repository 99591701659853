import React from "react";
import classes from "./myButton.module.scss";

const MyButton = ({ children, ...props }) => {
  return (
    <button
      {...props}
      style={{ background: props.color }}
      className={classes.myBtn}
    >
      {children}
    </button>
  );
};

export default MyButton;
