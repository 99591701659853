import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteImageServer,
  loadImageOneDetailServer,
} from "../../http/imageApi";
import style from "./galleryImagesServer.module.scss";

const GalleryImagesServer = ({ product }) => {
  const productPhoto = useSelector((state) => state.imageList.list);

  const handleDeleteImage = (imageId) => {
    dispatch(deleteImageServer(imageId));
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadImageOneDetailServer(product.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={style.galleryImageServer}>
      {productPhoto.length > 0 && Array.isArray(productPhoto) ? (
        productPhoto.map((image, index) => (
          <div key={index} style={{ position: "relative" }}>
            <img
              style={{ margin: "3px" }}
              alt=""
              src={`${process.env.REACT_APP_API_URL}/images/products/${image.mini}`}
            />
            <div
              className={style.btnDel}
              onClick={() => handleDeleteImage(image.id)}
            >
              <img
                src="/icon/udalit_64.png"
                alt="Удалить"
                style={{ width: "20px", height: "20px" }}
              />
            </div>
          </div>
        ))
      ) : (
        <p>Фото еще не загружены</p>
      )}
    </div>
  );
};

export default GalleryImagesServer;
