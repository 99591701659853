import { createSlice } from "@reduxjs/toolkit";

// Начальное состояние
const initialState = {
  isAuth: false,
};

// Создание слайса
const isAuthSlice = createSlice({
  name: "isAuth",
  initialState,
  reducers: {
    setIsAuth(state, action) {
      state.isAuth = action.payload;
    },
  },
});

// Экспортируем действия и редуктор
export const { setIsAuth } = isAuthSlice.actions;
export const isAuthReducer = isAuthSlice.reducer;
