import { createSlice } from "@reduxjs/toolkit";
import {
  createType,
  deleteType,
  updateType,
  loadTypes,
  getTypesByCategoryWithProducts,
} from "../../http/typeApi";

const typeSlice = createSlice({
  name: "types",
  initialState: {
    list: [],
    filterList: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle loadTypes
      .addCase(loadTypes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loadTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload;
      })
      .addCase(loadTypes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle createType
      .addCase(createType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createType.fulfilled, (state, action) => {
        state.loading = false;
        state.list.push(action.payload);
      })
      .addCase(createType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle deleteType
      .addCase(deleteType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteType.fulfilled, (state, action) => {
        state.loading = false;
        state.list = state.list.filter((item) => item.id !== action.payload.id);
      })
      .addCase(deleteType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle updateType
      .addCase(updateType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateType.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.list.findIndex(
          (item) => item.id === action.payload.id
        );
        if (index !== -1) {
          state.list[index] = action.payload;
        }
      })
      .addCase(updateType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getTypesByCategoryWithProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTypesByCategoryWithProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.filterList = action.payload;
      })
      .addCase(getTypesByCategoryWithProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// export default partTypeSlice.reducer;
export const typeReducer = typeSlice.reducer;
//export const modelReducer = modelSlice.reducer;
