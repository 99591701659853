import { $authHost, $host } from "./index";
import { createAsyncThunk } from "@reduxjs/toolkit";

// Загрузка всех типов деталей
export const loadTypes = createAsyncThunk(
  "@@type/load-type",
  async (_, { rejectWithValue }) => {
    try {
      const response = await $host.get("api/type");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Создание нового типа детали
export const createType = createAsyncThunk(
  "@@type/create-type",
  async (type, { rejectWithValue }) => {
    try {
      const response = await $authHost.post("api/type", type);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteType = createAsyncThunk(
  "@@type/delete-type",
  async (id, { rejectWithValue }) => {
    try {
      const response = await $authHost.delete(`api/type/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Обновление типа детали по id
export const updateType = createAsyncThunk(
  "@@type/update-type",
  async (
    { id, name, description, parentID, order, imageURL },
    { rejectWithValue }
  ) => {
    try {
      const response = await $authHost.patch(`api/type/${id}`, {
        name,
        description,
        parentID,
        order,
        imageURL,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTypesByCategoryWithProducts = createAsyncThunk(
  "@@type/filter-type",
  async ({ categoryId, userId }, { rejectWithValue }) => {
    try {
      const response = await $authHost.get("api/type/filter", {
        params: {
          categoryId,
          userId,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
